import { AutocompleteInput, Create, DateTimeInput, NumberInput, ReferenceInput, required, SelectInput, SimpleForm, TextInput } from "react-admin";

import { Typography } from "@mui/material";
import { RichTextInput } from "ra-input-rich-text";
import { useWatch } from "react-hook-form";
import { RaffleKinds } from "../Raffles";

function sanitizeSlug(value: string) {
    return value?.replace(/[^A-zÀ-ú0-9\s-_]/g, "")
        .replace(/\s/g, "-")
        .toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "");
}

export const SlugInput = (...props: any[]) => {
    const title = useWatch({ name: "title" });

    return (
        <TextInput
            fullWidth
            source="slug"
            format={(value) => value ? sanitizeSlug(value): sanitizeSlug(title)}
            value={sanitizeSlug(title)}
            validate={[required()]}
            helperText="O nome utilizado para o subdomínio deste sorteio (ex.: sorteio.luckymaker.app)"
            {...props}
        />
    );
}

export const RaffleCreate = () => {
    return (
        <Create>
            <SimpleForm>
                <Typography variant="h5" mb={3}>Criar sorteio</Typography>

                <style>{/*css*/`
                    .RaRichTextInput-editorContent .ProseMirror {
                        min-height: 200px;
                    }
                `}
                </style>

                <TextInput fullWidth source="title" validate={[required()]} />

                <SlugInput />

                <ReferenceInput
                    source="categoryId"
                    reference="raffleCategories"
                >
                    <AutocompleteInput
                        fullWidth
                        optionText="slug"
                        label="Categoria"
                        clearIcon={false}
                    />
                </ReferenceInput>

                <TextInput fullWidth source="edition" validate={[required()]} />

                <SelectInput fullWidth source="kind" validate={[required()]} choices={RaffleKinds} />

                <TextInput fullWidth source="settings.susepProcess" helperText="Ao manter vazio, será preenchido com o processo do último sorteio criado de mesmo tipo que este" />
                <RichTextInput fullWidth source="settings.regulation" helperText="Ao manter vazio, será preenchido com o regulamento do último sorteio criado de mesmo tipo que este" />

                <RichTextInput fullWidth source="description" validate={[required()]} />

                <TextInput multiline fullWidth source="excerpt" validate={[required()]} />

                <NumberInput fullWidth source="price" options={{ style: "currency", currency: "BRL" }} validate={[required()]} />

                <DateTimeInput helperText="A data e hora no qual o sorteio inicia as vendas." fullWidth source="startAt" validate={[required()]} />
                <DateTimeInput helperText="A data e hora no qual o sorteio encerra as vendas." fullWidth source="endAt" validate={[required()]} />

                <DateTimeInput helperText="A data e hora no qual o sorteio será realizado." fullWidth source="drawDate" validate={[required()]} />
            </SimpleForm>
        </Create>
    );
}
