import portugueseMessages from "ra-language-pt-br";

export const customPortugueseMessages = {
    ...portugueseMessages,

    menus: {
        users: "Usuários",
        affiliates: "Revendedores",
        financial: "Financeiro",
        partners: "Parceiros",
        raffles: "Sorteios",
        system: "Sistema",
        helpers: "Ferramentas",
        federalLotto: "Loteria Federal",
        forms: "Formulários",
        reports: "Relatórios",
        platform: "Plataforma",
        ambassadors: "Embaixadores"
    },

    helpers: {
        resultImageGenerator: {
            name: "Gerador de pós-resultados"
        },

        appControl: {
            name: "Controle da aplicação"
        },

        federalLotto: "Loteria Federal"
    },

    forms: {
        vivaSuaHistoria: {
            name: "Viva sua História"
        }
    },

    resources: {
        users: {
            name: "Usuários",
            fields: {
                id: "ID",
                email: "Endereço de e-mail",
                password: "Senha",
                name: "Nome",
                originalName: "Nome de Registro",
                socialName: "Nome Social",
                cpf: "CPF",
                cep: "CEP",
                neighborhood: "Bairro",

                // Here we need to support both
                address: "Endereço",
                "address.cep": "CEP",

                data: {
                    address: {
                        number: "Número",
                        complement: "Complemento"
                    }
                },
                city: "Cidade",
                state: "Estado",
                tel: "Telefone",
                createdAt: "Data de cadastro",
                birthdate: "Data de nascimento",
                creditsAmount: "Quantidade de créditos"
            }
        },

        userLuckyNumbers: {
            filters: {
                userName: "Filtrar por usuário",
                raffleTitle: "Filtrar por sorteio",
                affiliateName: "Filtrar por revendedor"
            },
            name: "Números da sorte",
            fields: {
                id: "ID",
                userId: "Usuário",
                raffleId: "Sorteio",
                number: "Número da sorte",
                paymentId: "ID do pagamento"
            }
        },

        financials: {
            name: "Contemplados a pagar",
            fields: {
                winner: {
                    name: "Contemplado",
                    prizeName: "Prêmio",
                },
                raffle: {
                    id: "Sorteio pai",
                    title: "Sorteio pai",
                    date: "Data do sorteio"
                },
                notes: "Notas"
            }
        },

        coupons: {
            name: "Cupons",

            fields: {
                id: "ID",
                code: "Código de Resgate (plano)",
                fancyCode: "Código de Resgate",
                expiresAt: "Data máx de resgate",
                benefitType: "Tipo do benefício",
                benefitAmount: "Quantidade do benefício",
                usedById: "Resgatado por",
                used: "Resgatado",
                createdAt: "Criado em",
                isExpiredNow: "Expirado"
            }
        },

        payments: {
            name: "Pagamentos",
            filters: {
                userName: "Filtrar por pagante",
                raffleTitle: "Filtrar por sorteio",
                affiliateName: "Filtrar por revendedor"
            },
            fields: {
                id: "ID",
                uuid: "ID público (UUID)",
                userId: "Usuário",
                method: "Método de pagamento",
                status: "Status do pagamento",
                value: "Valor total",
                affiliateId: "Revendedor",
                createdAt: "Criado em",
                updatedAt: "Atualizado em",
                cardId: "Cartão utilizado",
                message: "Mensagem",
                date: "Data",
                data: {
                    logs: "Histórico"
                },
                items: "Itens do carrinho"
            }
        },

        partners: {
            name: "Consumidores de API",
            filters: {
                userName: "Filtrar por pagante",
                raffleTitle: "Filtrar por sorteio",
                affiliateName: "Filtrar por revendedor"
            },
            fields: {
                id: "ID",
                name: "Nome",
                apiKey: "Chave de API",
                apiSecret: "Segredo de API",
                raffleAccessType: "Acesso à sorteios",
                raffleKindFilter: "Filtro de tipo de sorteio"
            }
        },

        raffles: {
            name: "Sorteios",

            tabs: {
                data: "Dados",
                entropy: "Entropia",
                winners: "Ganhadores",
                live: "Ao Vivo",
                style: "Estilo",
                gambling: "Apostas"
            },

            fields: {
                id: "ID",
                title: "Título",
                slug: "Slug",
                kind: "Tipo",
                description: "Descrição",
                excerpt: "Descrição curta",
                thumbnail: "Imagem em destaque",
                price: "Preço por número da sorte",
                status: "Estado",
                startAt: "Inicia em",
                endAt: "Finaliza em",
                drawDate: "Data do sorteio",
                edition: "Edição",
                resultHash: "Hash do resultado",
                matrixId: "Matriz",
                categoryId: "Categoria",
                settings: {
                    susepProcess: "Código do Processo SUSEP",
                    regulation: "Regulamento em texto corrido",

                    entropy: {
                        minNumber: "Menor número à ser gerado",
                        maxNumber: "Maior número à ser gerado",
                        numberOfChances: "Número de chances por compra",
                        federalLotto: {
                            contestNumber: "Núm. do Concurso da Loteria Federal",
                            numbers: "Núms. da Loteria Federal"
                        }
                    },

                    winners: {
                        name: "Nome do ganhador",
                        number: "Número do ganhador"
                    },

                    whereToWatch: {
                        facebook: "URL da live no Facebook",
                        youtube: "URL da live no YouTube",
                        twitch: "URL da live na Twitch"
                    },

                    colors: {
                        highlight: "Cor em destaque"
                    },

                    gambling: {
                        maximumBetPrice: "Valor máximo de aposta",
                        multipliers: "Multiplicadores de aposta"
                    }
                }
            }
        },

        raffleCategories: {
            name: "Categorias",
            fields: {
                id: "ID",
                slug: "Slug",
                label: "Título",
                description: "Descrição",
                displayOrder: "Ordem de exibição",
                iconUrl: "Ícone",
                unlisted: "Esconder categoria",
                raffles: "Sorteios",
                rafflesCount: "Quant. sorteios",
                color: "Cor"
            }
        },

        dailyRaffles: {
            name: "Sorteios diários",
            fields: {
                id: "ID",
                code: "Código",
                title: "Título do sorteio",
                prizeTitle: "Título do prêmio",
                parentRaffle: {
                    id: "Sorteio pai"
                },
                datetime: "Dia e hora de ocorrência",
                date: "Dia dos sorteios",
                rafflesCount: "Quant. Sorteios",
                region: "Região do país a sortear",
                time: "Horário",
                prizes: "Premiações",
                raffles: "Sorteios durante o dia",
                file: "Arquivo de resultados",
                user: {
                    id: "ID do Usuário",
                    name: "Contemplado(a)"
                },
                reseller: {
                    id: "ID de Revendedor(a)",
                    name: "Revendedor(a)"
                },
                luckyNumber: {
                    id: "ID do Número da Sorte",
                    number: "Número da Sorte"
                },
                winnersCount: "Quantidade de contemplações",
                contest: "Núm. do Concurso"
            }
        },

        rafflePrizes: {
            name: "Prêmios",
            fields: {
                id: "ID",
                raffleId: "Sorteio",
                name: "Nome do prêmio",
                siteName: "Nome do prêmio no site",
                raffleKind: "Tipo de sorteio",
                displayOrder: "Ordem de exibição",
                winnersAmount: "Quantidade de ganhadores",
                unlisted: "Esconder prêmio",
                thumbnail: "Imagem em destaque (aspecto 16:9)",

                data: {
                    drawRules: {
                        startAt: "Data / hora de início de busca",
                        endAt: "Data / hora do fim de busca"
                    }
                }
            }
        },

        raffleResults: {
            name: "Resultados",
            fields: {
                id: "ID",
                number: "Número sorteado",
                prizeId: "Prêmio",
                createdAt: "Sorteado em"
            }
        },

        raffleExternalResults: {
            name: "Resultados Externos",
            fields: {
                id: "ID",
                number: "Número sorteado",
                prizeId: "Prêmio",
                createdAt: "Inserido em",
                raffleId: "Sorteio",
                luckyNumber: "Número da sorte",
                "winner.name": "Ganhador",
                winnerPhotoUrl: "Foto do ganhador",
                reseller: "Revendedor",
                "reseller.name": "Revendedor"
            }
        },

        raffleLuckyNumbers: {
            name: "Números da sorte",
            fields: {
                id: "ID",
                number: "Número da sorte",
                raffleId: "Sorteio",
                reservationPaymentId: "Reservado para"
            }
        },

        admins: {
            name: "Administradores",
            fields: {
                id: "ID",
                name: "Nome de exibição",
                email: "Endereço de e-mail",
                password: "Senha",
                role: "Nível de acesso",
                raffles: "Sorteios visíveis"
            }
        },

        affiliates: {
            name: "Revendedores",
            fields: {
                id: "ID",
                name: "Nome completo",
                registrationStatus: "Situação cadastral",
                email: "Endereço de e-mail",
                password: "Senha",
                role: "Cargo",
                slug: "Identificador único",
                funds: {
                    title: "Ganhos",
                    balance: "Saldo",
                    bruteTotal: "Total bruto",
                    netTotal: "Total líquido",
                    withdrawed: "Sacado anteriormente"
                },
                data: {
                    PIXKey: "Chave PIX"
                }
            }
        },

        affiliateActivity: {
            name: "Atividade do revendedor",
            fields: {
                type: {
                    WITHDRAW: "Solicitação de saque"
                }
            }
        },

        ambassadors: {
            name: "Embaixadores",
            fields: {
                id: "ID",
                email: "E-mail",
                name: "Nome",
                password: "Senha",
                raffles: "Sorteios"
            }
        },

        readableRaffles: {
            name: "Sorteios Elegíveis",
            fields: {
                id: "ID",
                raffleId: "Sorteio",
                kind: "Tipo"
            }
        },

        resellersMaterial: {
            name: "Material do revendedor",
            fields: {
                title: "Título do material",
                active: "Está ativo?",
                url: "Imagem",
                category: "Categoria"
            }
        },

        managedPhysicalRanges: {
            name: "Lotes gerenciados",
            fields: {
                managerId: "Gerenciado por",
                fromBatch: "Do lote",
                toBatch: "Ao lote",
                batchesCount: "Quant. lotes",
                kind: "Modalidade do lote",
                skipCommissions: "Ignorar novas comissões",
                expiresAt: "Expira em",
                lastResetAt: "Apagado pela última vez em",
                filters: "Filtros",
                occupancyRatio: "Ocupação por revendedores",
                "filters.categories": "Categorias",
                bondsPerBatch: "Quant. títulos por lote"
            }
        },

        affiliatePhysicalRanges: {
            name: "Lotes de revendedor",
            fields: {
                affiliateId: "Revendido por",
                managerId: "Gerenciado por",
                fromBatch: "Do lote",
                toBatch: "Ao lote",
                batchNumber: "Núm. lote",
                batchesCount: "Quant. lotes"
            }
        },

        notifications: {
            name: "Notificações",
            fields: {
                id: "ID",
                contents: {
                    en: "Conteúdo"
                },
                headings: {
                    en: "Título"
                },
                included_segments: "Segmentos incluídos",
                excluded_segments: "Segmentos excluídos",
                include_external_user_ids: "Usuários incluídos",
                is_ios: "Enviada p/ iOS?",
                is_android: "Enviada p/ Android?",
                is_chrome_web: "Enviada p/ Chrome?",
                is_firefox: "Enviada p/ Firefox?",
                is_safari: "Enviada p/ Safari?"
            }
        },

        approvedPaymentsWithoutNumbers: {
            name: "Pagamentos sem números",
            fields: {
                id: "ID",
                uuid: "UUID",
                status: "Status",
                value: "Valor adquirido",
                raffleId: "Sorteio"
            }
        },

        federalLottoRaffles: {
            name: "Sorteios",
            fields: {
                id: "ID",
                title: "Título",
                federalLottoNumber: "Concurso da Loteria Federal",
                parentRaffleId: "Sorteio pai",
                drawnAt: "Sorteado em",
                createdAt: "Criado em",
                updatedAt: "Atualizado em",
                data: {
                    federalLotto: {
                        contestNumber: "Número do concurso",
                        numbers: "Dezenas sorteadas"
                    },

                    quina: {
                        contestNumber: "Número do concurso",
                        numbers: "Dezenas sorteadas"
                    }
                }
            }
        },

        federalLottoRafflePrizes: {
            name: "Prêmios e Resultados",
            fields: {
                id: "ID",
                title: "Nome do prêmio",
                winningNumberId: "Número vencedor",
                raffleId: "Sorteio",
                data: {
                    supportNumber: "Número de Suporte (NS)",
                    calculatedNumber: "Título / número calculado (TC)"
                }
            }
        },

        matrix: {
            name: "Matrizes",
            fields: {
                id: "ID",
                name: "Nome",
                size: "Tamanho",
                numbersPerBond: "Números por cartela",
                numberOfChances: "Quantidade de chances"
            }
        },

        formSubmissions: {
            name: "Viva sua História",
            fields: {
                id: "ID",
                fields: {
                    name: "Nome",
                    phone: "Telefone",
                    email: "Endereço de e-mail",
                    city: "Cidade",
                    state: "Estado",
                    address: "Endereço",
                    message: "História"
                },
                createdAt: "Enviado em"
            }
        },

        database: {
            name: "Consultas no banco de dados",
            fields: {
                id: "ID",
                user: "Usuário",
                time: "Tempo de execução (em segundos)",
                state: "Estado",
                info: "Informações / comando",

                stats: {
                    inQuery: "Está executando consulta?",
                    inTransaction: "Está executando transação?",
                    executedQueries: "Consultas executadas"
                }
            }
        },

        plugins: {
            name: "Plugins",
            fields: {
                name: "Nome",
                description: "Descrição",
                enabled: "Habilitado?"
            }
        }
    }
};
