import { Typography } from "@mui/material";
import { BooleanField, Datagrid, DateField, FormDataConsumer, FunctionField, ImageField, Pagination, ReferenceManyField, Show, SimpleShowLayout, TextField } from "react-admin";
import { CreateButtonWithDefaults } from "../../../parts/CreateButtonWithDefaults";
import { EmptyListBody } from "../../../parts/EmptyListBody";
import { RecordConsumer } from "../../../parts/RecordConsumer";
import { ColorField } from "react-admin-color-picker";

export const RaffleCategoriesShow = () => (
    <Show>
        <SimpleShowLayout>
            <ImageField emptyText="Nenhuma imagem selecionada" label="Imagem destacada" source="iconUrl.src" />
            <TextField source="label" />
            <TextField source="slug" />
            <ColorField source="color" />
            <TextField source="description" />
            <TextField source="displayOrder" />
            <TextField source="rafflesCount" />
            <BooleanField source="unlisted" />

            <RecordConsumer>
                {(record) =>
                    <div className="my-6">
                        <div className="flex flex-wrap justify-between md:flex-nowrap">
                            <Typography variant="h6">
                                Sorteios desta categoria
                            </Typography>

                            <CreateButtonWithDefaults
                                resource="raffles"
                                label="Criar e adicionar"
                                defaults={{
                                    categoryId: record?.id
                                }}
                            />
                        </div>

                        <div className="mt-6">
                            <ReferenceManyField pagination={<Pagination />} reference="raffles" target="categoryId">
                                <Datagrid rowClick={"show"} bulkActionButtons={false} empty={<EmptyListBody label="Ainda não há sorteios nesta categoria." />}>
                                    <TextField source="id" />
                                    <DateField source="drawDate" />
                                    <TextField source="title" />
                                </Datagrid>
                            </ReferenceManyField>
                        </div>
                    </div>
                }
            </RecordConsumer>
        </SimpleShowLayout>
    </Show>
);
