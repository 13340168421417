import { AutocompleteInput, DateTimeInput, Edit, FormTab, ImageField, ImageInput, NumberInput, ReferenceInput, required, SelectInput, TabbedForm, TextInput, useRecordContext, useTranslate } from "react-admin";

import { RichTextInput } from "ra-input-rich-text";
import { useMemo } from "react";
import { ColorInput } from "react-admin-color-picker";
import { RaffleKinds, RaffleStatus } from "../Raffles";
import { RaffleEntropyTab } from "./tabs/RaffleEntropyTab";

export const RaffleEdit = () => {
    return (
        <Edit>
            <RaffleEditForm />
        </Edit>
    );
}

const RaffleEditForm = () => {
    const translate = useTranslate();
    const record = useRecordContext();

    const isGamblingRaffle = useMemo(() => record.kind === "GAMBLING", [record]);

    return (
        <>
            <style>{/*css*/`
                .RaRichTextInput-editorContent .ProseMirror {
                    min-height: 200px;
                }
            `}
            </style>

            <TabbedForm>
                <FormTab label={translate("resources.raffles.tabs.data")}>
                    <TextInput fullWidth source="title" validate={[required()]} />

                    <SelectInput
                        fullWidth
                        source="kind"
                        choices={RaffleKinds}
                        validate={[required()]}
                    />

                    <TextInput fullWidth source="edition" validate={[required()]} />

                    <RichTextInput fullWidth source="description" validate={[required()]} />

                    <TextInput multiline fullWidth source="excerpt" validate={[required()]} />

                    <ReferenceInput
                        source="categoryId"
                        reference="raffleCategories"
                    >
                        <AutocompleteInput
                            fullWidth
                            optionText="slug"
                            label="Categoria"
                            clearIcon={false}
                        />
                    </ReferenceInput>

                    <ImageInput
                        fullWidth
                        accept="image/png, image/jpg, image/jpeg"
                        source="thumbnail"
                    >
                        <ImageField
                            fullWidth
                            source="src"
                            title="title"
                        />
                    </ImageInput>

                    <TextInput fullWidth source="slug" validate={[required()]} />

                    { !isGamblingRaffle &&
                        <NumberInput
                            fullWidth
                            source="price"
                            options={{ style: "currency", currency: "BRL" }}
                            validate={[required()]}
                        />
                    }

                    <SelectInput fullWidth source="status" validate={[required()]} choices={RaffleStatus} />

                    <TextInput fullWidth source="settings.susepProcess" />
                    <RichTextInput fullWidth source="settings.regulation" />

                    <DateTimeInput helperText="A data e hora no qual o sorteio inicia as vendas." fullWidth source="startAt" validate={[required()]} />
                    <DateTimeInput helperText="A data e hora no qual o sorteio encerra as vendas." fullWidth source="endAt" validate={[required()]} />

                    <DateTimeInput helperText="A data e hora no qual o sorteio será realizado." fullWidth source="drawDate" validate={[required()]} />
                </FormTab>

                <FormTab label={translate("resources.raffles.tabs.entropy")}>
                    <RaffleEntropyTab />
                </FormTab>

                <FormTab label={translate("resources.raffles.tabs.style")}>
                    <ColorInput
                        source="settings.colors.highlight"
                        picker="Sketch"
                    />
                </FormTab>

                {
                    isGamblingRaffle &&
                        <FormTab label={translate("resources.raffles.tabs.gambling")}>
                            <NumberInput
                                fullWidth
                                source="price"
                                label="Valor mínimo de aposta"
                                options={{ style: "currency", currency: "BRL" }}
                                validate={[required()]}
                                helperText="O valor do sorteio e também o valor mínimo de aposta que poderá ser investido em um título."
                            />

                            <TextInput
                                fullWidth
                                type="number"
                                source="settings.gambling.maximumBetPrice"
                                validate={[required()]}
                                helperText="O valor máximo de aposta que poderá ser investido em um título."
                            />

                            <NumberInput
                                fullWidth
                                source="settings.gambling.maximumMultiplier"
                                label="Multiplicador máximo de aposta"
                                validate={[required()]}
                                helperText="O multiplicador máximo de aposta. Também será utilizado como a quantidade de números que serão entregues ao usuário."
                            />
                        </FormTab>
                }

                <FormTab label={translate("resources.raffles.tabs.live")}>
                    <TextInput fullWidth type="url" source="settings.whereToWatch.facebook" />
                    <TextInput fullWidth type="url" source="settings.whereToWatch.youtube" />
                    <TextInput fullWidth type="url" source="settings.whereToWatch.twitch" />
                </FormTab>
            </TabbedForm>
        </>
    );
}
