import { AutocompleteInput, BooleanInput, DateTimeInput, FormDataConsumer, ImageField, ImageInput, NumberInput, ReferenceInput, SaveButton, SelectInput, TabbedForm, TextInput, Toolbar, maxLength, minLength, required } from "react-admin";
import { RafflePrizeKind } from "../../Raffles";
import { BrazilianStates } from "../../../../core/BrazilianStates";
import { useFormContext } from "react-hook-form";

export const RafflePrizeForm = (props: {
    kind: "CREATE" | "EDIT"
}) => {
    return (
        <TabbedForm
            toolbar={
                <>
                    <Toolbar>
                        <SaveButton alwaysEnable label={props.kind === "CREATE" ? "Criar" : "Editar"} />
                    </Toolbar>
                </>
            }
        >
            <TabbedForm.Tab label="Dados">
                <TextInput fullWidth source="name" validate={[required()]} helperText="Dê um nome ao prêmio" />

                <TextInput fullWidth source="siteName" validate={[required()]} helperText="Dê um nome ao prêmio que aparecerá no site" />

                <SelectInput fullWidth source="raffleKind" choices={RafflePrizeKind} validate={[required()]} helperText="O tipo de execução do sorteio relacionado à este prêmio" />

                <ImageInput
                    fullWidth
                    accept="image/png, image/jpg, image/jpeg"
                    source="thumbnail"
                >
                    <ImageField fullWidth source="src" title="title" />
                </ImageInput>

                <NumberInput fullWidth source="displayOrder" helperText="A ordem / posição no qual este prêmio será exibido" />

                <BooleanInput source="unlisted" helperText="Caso marcado, não aparecerá em listagens públicas" />

                <ReferenceInput source="raffleId" reference="raffles" validate={[required()]}>
                    <AutocompleteInput fullWidth optionText="title" helperText="O sorteio relacionado à este prêmio" />
                </ReferenceInput>

                <FormDataConsumer>
                    {({ formData }) =>
                        ["LUCKY_NUMBERS", "BY_SELL_ORDER", "FEDERAL_LOTTO_RANDOM", "FEDERAL_LOTTO_RANDOM_ALT"].includes(formData.raffleKind) ? (
                            <SelectInput
                                source="winnersAmount"
                                helperText={
                                    <span>
                                        {"A quantidade de ganhadores sorteados neste prêmio."}
                                        <br/>
                                        {"Caso seja selecionado mais de um ganhador, será utilizado o layout de sorteio múltiplo no sorteador"}
                                    </span>
                                }
                                required
                                defaultValue={1}
                                choices={[
                                    { id: 1, name: 1 },
                                    { id: 5, name: 5 },
                                    { id: 10, name: 10 },
                                    { id: 15, name: 15 },
                                    { id: 20, name: 20 },
                                    { id: 25, name: 25 }
                                ]}
                            />
                        ) : null
                    }
                </FormDataConsumer>
            </TabbedForm.Tab>

            <TabbedForm.Tab label="Configurações de sorteio">
                <PrizeConfigsTab />
            </TabbedForm.Tab>
        </TabbedForm>
    )
};

const PrizeConfigsTab = () => {
    const form = useFormContext();

    return <>
        <DateTimeInput
            fullWidth
            source="data.drawRules.startAt"
            helperText="A data e hora final para filtrar os pagamentos ao procurar pelos ganhadores."
        />

        <DateTimeInput
            fullWidth
            source="data.drawRules.endAt"
            helperText="A data e hora inicial para filtrar os pagamentos ao procurar pelos ganhadores."
        />

        <FormDataConsumer>
            {({ formData }) => (
                <>
                    <SelectInput
                        fullWidth
                        disabled={!formData.raffleKind || formData.raffleKind === "MATRIXES"}
                        source="data.drawRules.userState"
                        choices={ [...BrazilianStates, { name: "Qualquer", value: undefined }] }
                        label="Estado (UF)"
                        helperText="Estado em que o ganhador deve pertencer"
                        emptyText="Qualquer"
                        placeholder="Qualquer"
                        validate={[minLength(2), maxLength(2)]}
                        onChange={(event) => {
                            if (!event.target.value) {
                                form.setValue("data.drawRules.userCity.name", null);
                            }
                        }}
                    />

                    <TextInput
                        disabled={!formData.data?.drawRules?.userState || !formData.raffleKind || formData.raffleKind === "MATRIXES"}
                        source="data.drawRules.userCity.name"
                        label="Cidade"
                        helperText="Cidade em que o ganhador deve pertencer"
                        placeholder="Qualquer"
                        value={1}
                    />
                </>
            )}
        </FormDataConsumer>
    </>
}
